/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import DayJSUtils from '@date-io/dayjs';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import * as Sentry from '@sentry/react';
import React, {useEffect} from 'react';
import {createBrowserRouter, Navigate, Outlet, RouterProvider, useNavigate, useNavigation, useRouteError} from 'react-router-dom';

import Header from 'containers/Header';
import injectRootSagas from 'modules/sagas';
import InventoryVisualizationPage from 'pages/InventoryVisualizationPage/Loadable';
import LoginPage from 'pages/LoginPage/Loadable';
import MaterialPage from 'pages/MaterialPage/Loadable';
import NotFoundPage from 'pages/NotFoundPage/Loadable';
import OpportunitiesPage from 'pages/OpportunitiesPage/Loadable';
import OrdersPage from 'pages/OrdersPage/Loadable';
import RecoverPage from 'pages/RecoverPage/Loadable';
import ReportsPage from 'pages/ReportsPage/Loadable';
import ResetPage from 'pages/ResetPage/Loadable';
import SafetyStockPage from 'pages/SafetyStockPage/Loadable';
import SimulatorCalculatingPage from 'pages/SimulatorCalculatingPage';
import {loader as simulatorEditionLoader, SimulatorEditionPage} from 'pages/SimulatorEditionPage';
import SimulatorPage from 'pages/SimulatorPage';
import {loader as simulatorResultsLoader, SimulatorResultsPage} from 'pages/SimulatorResultsPage';
import {loader as simulatorRoutingLoader, SimulatorRoutingPage} from 'pages/SimulatorRoutingPage';
import ExperimentsPage from 'pages/ExperimentsPage/Loadable';
import LoadingIndicator from 'components/LoadingIndicator';
import {comparisonListLoader, comparisonLoader} from 'loaders';
import theme from 'theme';
import Joyride from '../Joyride';
import styles from './styles.scss';
import {setNavigate} from 'modules/routing/navigateFn';

const Layout = ({ children }) => {
	const navigation = useNavigation();
	const navigate = useNavigate();
	const [isNavigate, setIsNavigate] = React.useState(false);

	useEffect(() => {
		setNavigate(navigate);
		setIsNavigate(true);
	}, [navigate]);

	if (!isNavigate) {
		return <LoadingIndicator />;
	}

	return (
		<div className={styles.App}>
			<Header />
			<Joyride />
			{navigation.state === 'loading' && <LoadingIndicator />}
			<main className={styles.Main}>
				{children}
			</main>
		</div>
	);
};

export const Fallback = () => {
	const routeError = useRouteError();
	if (routeError?.response?.status === 404) {
		return (
			<Layout>
				<NotFoundPage />
			</Layout>);
	}
	return (
		<Layout>
			<h1 style={{ padding: '20px' }}>Something went wrong.</h1>
		</Layout>
	);
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

function App() {
	injectRootSagas();

	const router = sentryCreateBrowserRouter([
		{
			path: '/',
			element: <Layout><Outlet /></Layout>,
			errorElement: <Fallback />, // Fallback component for errors
			children: [
				{ path: '/', element: <Navigate to="/orders" /> },
				{ path: '/orders', element: <OrdersPage /> },
				{ path: '/reports', element: <OpportunitiesPage /> },
				{ path: '/material/:materialNumber', element: <MaterialPage /> },
				{ path: '/safetystock', element: <SafetyStockPage /> },
				{ path: '/inventory', element: <InventoryVisualizationPage /> },
				{ path: '/co2reports', element: <ReportsPage /> },
				{ path: '/experiments', element: <ExperimentsPage /> },
				{
					path: '/simulator',
					element: <SimulatorPage />,
					loader: comparisonListLoader
				},
				{
					path: '/simulator/:comparisonId',
					element: <SimulatorRoutingPage />,
					loader: simulatorRoutingLoader
				},
				{
					path: '/simulator/edit/:comparisonId',
					element: <SimulatorEditionPage />,
					loader: simulatorEditionLoader
				},
				{
					path: '/simulator/calculate/:comparisonId',
					element: <SimulatorCalculatingPage />,
					loader: comparisonLoader
				},
				{
					path: '/simulator/results/:comparisonId',
					element: <SimulatorResultsPage />,
					loader: simulatorResultsLoader
				},
				{ path: '/login', element: <LoginPage /> },
				{ path: '/reset', element: <ResetPage /> },
				{ path: '/recover', element: <RecoverPage /> },
				{ path: '/not-found', element: <NotFoundPage /> },
				{ path: '*', element: <NotFoundPage /> }
			]
		}
	]);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<MuiPickersUtilsProvider utils={DayJSUtils}>
				<Sentry.ErrorBoundary fallback={Fallback}>
					<RouterProvider router={router} />
				</Sentry.ErrorBoundary>
				<ToastContainer />
			</MuiPickersUtilsProvider>
		</ThemeProvider>
	);
}

export default App;
