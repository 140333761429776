
export const formatNumber = (value, digits = 2, def = '') => {
	// Return default value if input is invalid
	if (value === undefined || value === null || value === '' || Number.isNaN(value)) return def;

	// Helper function to format number with grouping and decimal places
	const formatWithGrouping = (num, decimalPlaces) => {

		num = Number(num);
		if (Number.isNaN(num)) return '';
		const parts = num.toFixed(decimalPlaces).split('.');// Convert number to string with fixed decimal places
		// Add thin space as thousands separator every 3 digits from the right
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\u2009');
		// Join integer and decimal parts with a comma as decimal separator
		return parts.join(',');
	};

	// Handle negative digits (e.g., -1 for rounding to nearest 10, -2 for nearest 100, etc.)
	if (digits < 0) {
		const t = Math.abs(digits);
		const exp = 10 ** t;
		return formatWithGrouping(Math.round(value / exp) * exp, 0);
	}

	return formatWithGrouping(value, digits);
};

/**
 * This function remove trailing zeros like:
 * 1.40 => 1.4
 * 1.00 => 1
 *
 * @param value
 * @param digits
 */
export const formatShortNumber = (value, digits = 2) => {
	let str = formatNumber(value, digits);
	if (str === '') return '';
	while (str.substring(str.length - 1) === '0' && (str.indexOf('.') > -1 || str.indexOf(',') > -1)) {
		str = str.slice(0, -1);
	}
	if (str.substring(str.length - 1) === ',' || str.substring(str.length - 1) === '.') str = str.slice(0, -1);
	return str;
};

export const formatInteger = value => formatNumber(value, 0);

export const formatPrice = value => formatNumber(value, 2);

export const formatRoundedCurrency = value => formatNumber(value, -1);

export const formatPercentage = value => formatNumber(value, 2);

export const formatShortPercentage = value => formatShortNumber(value, 2);

export const formatQuantity = value => formatNumber(value, 3);

export const formatShortQuantity = value => formatShortNumber(value, 3);

export const formatCO2 = value => formatShortNumber(value, 3);

export const relativeDiff = (a, b) => {
	if (b === 0) return a === 0 ? 0 : 1;
	return -(b - a) / Math.abs(b);
};

export const percentDiff = (a, b) => 100 * relativeDiff(a, b);

export const capitalize = word => {
	if (typeof word !== 'string') return '';
	const firstChar = word.charAt(0).toUpperCase();
	const restOfString = word.slice(1).toLowerCase()
	return firstChar + restOfString;
}

export const capitalizeAll = (string) => {
	return string.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
};
