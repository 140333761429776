import { formatNumber } from 'utils/format';

const materialChartItems = [
	'consumptions', 'fixedOrderDeliveries', 'qmLotDeliveries', 'safetyStock', 'maxInventory',
	'comparisonDeliveries', 'comparisonScrap', 'comparisonAvailableInventory', 'comparisonDeliveredInventory', 'comparisonShiftedInventory',
	'genLotsDeliveries', 'genLotsScrap', 'genLotsAvailableInventory', 'genLotsDeliveredInventory', 'genLotsShiftedInventory'
];
const simulationChartItems = [
	'consumptions', 'fixedOrderDeliveries', 'qmLotDeliveries',
	'comparisonDeliveries', 'comparisonScrap', 'comparisonAvailableInventory', 'comparisonDeliveredInventory', 'comparisonShiftedInventory', 'comparisonSafetyStock', 'comparisonMaxInventory',
	'genLotsDeliveries', 'genLotsScrap', 'genLotsAvailableInventory', 'genLotsDeliveredInventory', 'genLotsShiftedInventory', 'genLotsSafetyStock', 'genLotsMaxInventory'
];

const invVizItems = [
	'capacity', 'fixedOrderDeliveries', 'qmLotDeliveries', 'consumptions',
	'comparisonInventory', 'comparisonTotalInventory', 'comparisonScrap', 'comparisonDeliveries',
	'genLotsInventory', 'genLotsTotalInventory', 'genLotsScrap', 'genLotsDeliveries'];

export const chartData = [
	{ id: 'consumptions', label: 'Consumption', color: '#00C2AF', iconType: 'square', chartType: 'bar', group: 'Other' },
	{ id: 'fixedOrderDeliveries', label: 'Fixed orders', color: '#56646E', iconType: 'square', chartType: 'bar', group: 'Other' },
	{
		id: 'qmLotDeliveries',
		label: 'QM Lot Deliveries',
		shortLabel: 'QM Lots.',
		color: '#000000',
		iconType: 'square',
		chartType: 'bar',
		group: 'Other'
	},
	{ id: 'safetyStock', label: 'Safety stock', color: '#00C2AF', iconType: 'dots', chartType: 'line', group: 'Other' },
	{ id: 'maxInventory', label: 'Max. inventory', color: '#E44600', iconType: 'dots', chartType: 'line', group: 'Other' },
	{ id: 'capacity', label: 'Capacity', color: '#E44600', iconType: 'line', chartType: 'line', group: 'Other' },

	{
		id: 'comparisonDeliveries',
		label: 'SAP order',
		shortLabel: 'Order',
		color: '#C0C0C0',
		iconType: 'square',
		chartType: 'bar',
		group: 'Comparison'
	},
	{
		id: 'comparisonScrap',
		label: 'SAP scrap',
		shortLabel: 'Scrap',
		color: '#C0C0C0',
		iconType: 'scrap',
		chartType: 'bar',
		group: 'Comparison'
	},
	{
		id: 'comparisonAvailableInventory',
		label: 'SAP available inventory',
		shortLabel: 'Available inventory',
		color: '#C0C0C0',
		iconType: 'line',
		chartType: 'line',
		group: 'Comparison'
	},
	{
		id: 'comparisonDeliveredInventory',
		label: 'SAP delivered inventory',
		shortLabel: 'Delivered inventory',
		color: '#C0C0C0',
		iconType: 'dashedLine',
		chartType: 'line',
		group: 'Comparison'
	},
	{
		id: 'comparisonShiftedInventory',
		label: 'SAP shifted avail.inventory',
		shortLabel: 'Shifted avail. Inventory',
		color: '#C0C0C0',
		iconType: 'dots',
		chartType: 'line',
		group: 'Comparison'
	},
	{ id: 'comparisonSafetyStock', label: 'Safety stock', color: '#C0C0C0', iconType: 'dots', chartType: 'line', group: 'Comparison' },
	{
		id: 'comparisonMaxInventory',
		label: 'SAP max. inventory',
		shortLabel: 'Max. inventory',
		color: '#825150',
		iconType: 'dots',
		chartType: 'line',
		group: 'Comparison'
	},
	{
		id: 'comparisonInventory',
		label: 'SAP available inventory',
		shortLabel: 'Available inventory',
		color: '#C0C0C0',
		iconType: 'line',
		chartType: 'line',
		group: 'Comparison'
	},
	{
		id: 'comparisonTotalInventory',
		label: 'SAP Total inventory',
		shortLabel: 'Total inventory',
		color: '#C0C0C0',
		iconType: 'line',
		chartType: 'line',
		group: 'Comparison'
	},

	{
		id: 'genLotsDeliveries',
		label: 'GenLots order',
		shortLabel: 'Order',
		color: '#5B63B6',
		iconType: 'square',
		chartType: 'bar',
		group: 'GenLots'
	},
	{
		id: 'genLotsScrap',
		label: 'GenLots scrap',
		shortLabel: 'Scrap',
		color: '#5B63B6',
		iconType: 'scrap',
		chartType: 'bar',
		group: 'GenLots'
	},
	{
		id: 'genLotsAvailableInventory',
		label: 'GenLots available inventory',
		shortLabel: 'Available inventory',
		color: '#5B63B6',
		iconType: 'line',
		chartType: 'line',
		group: 'GenLots'
	},
	{
		id: 'genLotsDeliveredInventory',
		label: 'GenLots delivered inventory',
		shortLabel: 'Delivered inventory',
		color: '#5B63B6',
		iconType: 'dashedLine',
		chartType: 'line',
		group: 'GenLots'
	},
	{
		id: 'genLotsShiftedInventory',
		label: 'GenLots shifted avail. inventory',
		shortLabel: 'Shifted avail. Inventory',
		color: '#5B63B6',
		iconType: 'dots',
		chartType: 'line',
		group: 'GenLots'
	},
	{ id: 'genLotsSafetyStock', label: 'Safety stock', color: '#00C2AF', iconType: 'dots', chartType: 'line', group: 'GenLots' },
	{
		id: 'genLotsMaxInventory',
		label: 'GenLots max. inventory',
		shortLabel: 'Max. inventory',
		color: '#E44600',
		iconType: 'dots',
		chartType: 'line',
		group: 'GenLots'
	},
	{
		id: 'genLotsInventory',
		label: 'GenLots available inventory',
		shortLabel: 'Available inventory',
		color: '#5B63B6',
		iconType: 'line',
		chartType: 'line',
		group: 'GenLots'
	},
	{
		id: 'genLotsTotalInventory',
		label: 'GenLots total inventory',
		shortLabel: 'Total inventory',
		color: '#5B63B6',
		iconType: 'line',
		chartType: 'line',
		group: 'GenLots'
	}

];

const materialChartData = chartData.filter(item => materialChartItems.includes(item.id));
const simulationChartData = chartData.filter(item => simulationChartItems.includes(item.id));
const invVizChartData = chartData.filter(item => invVizItems.includes(item.id));

export const getConfig = ({ type }) => {
	if (type === 'simulation' || type === 'SSO') return simulationChartData;
	if (type === 'invViz') return invVizChartData;
	return materialChartData;
};

export const getLegendConfig = ({ type, showMaxInventory, oldSSO, newSSO }) => {
	const chartData = getConfig({ type });
	const genLotsRow = chartData.filter(item => item.group === 'GenLots').map(item => item.id);
	const comparisonRow = chartData.filter(item => item.group === 'Comparison').map(item => item.id);
	const otherRow = chartData.filter(item => item.group === 'Other').map(item => item.id);

	const nColumnsGenLots = showMaxInventory
		? genLotsRow.filter(item => item !== 'genLotsShiftedInventory').length
		: genLotsRow.filter(item => item !== 'genLotsShiftedInventory' && item !== 'genLotsMaxInventory').length;
	const nColumnsOther = showMaxInventory
		? otherRow.length
		: otherRow.filter(item => item !== 'maxInventory').length;

	const nColumns = Math.max(nColumnsGenLots, nColumnsOther);
	const padItems = (items, n) => {
		const nC = items.some(item => /shifted/i.test(item)) ? n + 1 : n;
		while (items.length < nC) {
			items.push(null);
		}
		return items;
	};
	let titleRowOne = 'GenLots Optimized';
	let titleRowTwo = 'Current Plan';
	if (type === 'simulation') {
		titleRowOne = 'Simulation';
		titleRowTwo = 'Base';
	} else if (type === 'SSO') {
		titleRowOne = `${newSSO || 'New'} Service Level`;
		titleRowTwo = `${oldSSO || 'Old'} Service Level`;
	}

	return [
		{ title: titleRowOne, items: padItems(genLotsRow, nColumns) },
		{ title: titleRowTwo, items: padItems(comparisonRow, nColumns) },
		{ title: ' ', items: padItems(otherRow, nColumns) }
	];
};

export const getLabel = (id, short = false, inv = false) => {
	const longLabel = chartData.find(item => item.id === id)['label'];
	if (!short) return longLabel;
	const shortLabel = chartData.find(item => item.id === id)['shortLabel'];
	const finalLabel = shortLabel || longLabel;
	return inv ? finalLabel.replace('inventory', 'inv.') : finalLabel;
};

export const graphConfig = {
	bar: { width: { ratio: 0.9 } },
	data: {
		order: null,
		names: chartData.reduce((acc, item) => ({ ...acc, [item.id]: item.label }), {}),
		colors: {
			...chartData.reduce((acc, item) => ({ ...acc, [item.id]: item.color }), {}),
			consumptions2: '#00C2AF', consumptions3: '#00C2AF'
		},
		types: {
			...chartData.reduce((acc, item) => ({ ...acc, [item.id]: item.chartType }), {}),
			consumptions2: 'bar', consumptions3: 'bar',
			faker: 'line', faker2: 'line'
		},
		groups: [
			['consumptions', 'qmLotDeliveries', 'fixedOrderDeliveries'],
			['consumptions2', 'genLotsDeliveries', 'genLotsScrap'],
			['consumptions3', 'comparisonDeliveries', 'comparisonScrap']
		]
	},
	grid: {
		x: { show: false },
		y: { show: true, lines: [{ value: 0, class: 'base-line' }] }
	},
	hide: ['genLotsShiftedInventory', 'comparisonShiftedInventory'],
	legend: { show: false, position: 'inset', inset: { anchor: 'top-left', x: 0, y: -60, step: 2 } },
	onrendered() {
		// Dotted x-axis line
		this.internal.$el.axis.x.selectAll('.tick line').attr('y2', 1);
	},
	padding: { left: 100, top: 10, right: 100 },
	point: { show: false },
	tooltip: {
		format: { value: val => (val ? formatNumber(val) : undefined) }
	},
	transition: { duration: 0 },
	zoom: { enabled: true }
};
