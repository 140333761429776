import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SvgPatterns from './SvgPatterns';
import Collapse from '@material-ui/core/Collapse';
import { mergeClasses } from 'utils/classHelper';
import styles from './styles.scss';
import { Legend, ToolBar, ToolBarInvViz } from './legend';
import { createChart, createInvVizChart } from './createChart';


export const ResultsChart = ({ material, features, className, type = 'material' }) => {
	// type can be 'material', 'SSO' or 'simulation' For type 'invViz' use InventoryResultsChart below
	const [toggledLabels, setToggledLabels] = useState(features?.material?.chartLabels || false);
	const [shiftedInventory, setShiftedInventory] = useState(false);
	const [expandLegend, setExpandLegend] = useState(true);
	const [chart, setChart] = useState(null);

	useEffect(() => {
		const chartElement = document.getElementById('chart');
		const newChart = createChart({ expandLegend, chartElement, material, shiftedInventory, type });
		setChart(newChart);
		window.chart = newChart;

		return () => newChart.destroy();
	}, [material, shiftedInventory, expandLegend]);
	const showMaxInventory = material.maxInventory || material.base?.maxInventory;
	const toolBarServiceLevel = (type === 'material') && (material?.serviceLevel || material?.calculation?.serviceLevel);
	return (
		<div className={mergeClasses(styles.ResultsChart, !toggledLabels ? styles.HideToggledLabels : null)}>


			<ToolBar chart={chart} serviceLevel={toolBarServiceLevel} safetyTime={material.safetyTime}
							 toggledLabels={toggledLabels} setToggledLabels={setToggledLabels}
							 expandLegend={expandLegend} setExpandLegend={setExpandLegend}
							 shiftedInventory={shiftedInventory} setShiftedInventory={setShiftedInventory}
			/>
			<Collapse in={expandLegend}>
				<Legend
					chart={chart}
					showMaxInventory={showMaxInventory}
					shiftedInventory={shiftedInventory}
					type={type}
					oldSSO={material?.serviceLevel}
					newSSO={material?.calculation?.serviceLevel}
				/>
			</Collapse>


			<div id="chart" className={mergeClasses(styles.Chart, className)} />

			<SvgPatterns />
		</div>
	);
};

ResultsChart.propTypes = {
	material: PropTypes.object.isRequired
};

export default ResultsChart;


export const InventoryResultsChart = ({ data, options, filters }) => {
	const [toggledPallets, setToggledPallets] = useState(false);
	const [chart, setChart] = useState(null);

	useEffect(() => {
		const chartElement = document.getElementById('chart');
		const newChart = createInvVizChart({ chartElement, data, toggledPallets });
		setChart(newChart);
		window.chart = newChart;
		return () => newChart.destroy();
	}, [data, options, filters, toggledPallets]);

	return (
		<div className={styles.ResultsChart}>
			<ToolBarInvViz chart={chart} toggledPallets={toggledPallets} setToggledPallets={setToggledPallets} />
			<div id="chart" className={styles.Chart}>Inventory Visualization</div>
			<Legend chart={chart} />
			<SvgPatterns />
		</div>
	);


};
