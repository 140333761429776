// chart legend
import React from 'react';
import {Col, Row} from 'react-flexbox-grid';
import Text from 'components/Text';
import CustomIcon from './CustomIcon';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import ToggleButton from 'components/button/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Label from '@material-ui/icons/Label';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import styles from './styles.scss';
import {onFocused, onToggle, zoomIn, zoomOut} from './utils';
import {formatShortPercentage} from 'utils/format';
import {getLabel, getLegendConfig} from './config';

const LegendItem = ({ id, onToggle, onFocus, onBlur, icon, text, additionalClassName }) => (
	<Col
		xs={2}
		sm
		className={`${styles.ColInfo} ${additionalClassName}`}
		onClick={e => onToggle(id, e)}
		onMouseOver={() => onFocus(id)}
		onMouseOut={onBlur}
	>
		<div>
			{icon}
			<Text className={styles.Text}>{text}</Text>
		</div>
	</Col>
);

export const Legend = ({ chart, showMaxInventory, shiftedInventory, type, oldSSO, newSSO }) => {
	const config = getLegendConfig({
		showMaxInventory, type,
		oldSSO: `${formatShortPercentage(oldSSO * 100)}%` || false,
		newSSO : `${formatShortPercentage(newSSO * 100)}%` || false
	});
	return (
		<div className={styles.Legend}>
			{config.map((section, index) => (
				<Row key={index} className={styles.LegendRow}>
					{section.title && (
						<Col xs={2} className={index === 0 ? styles.GenLotsLabel : styles.ComparisonLabel}>
							<span>{section.title}</span>
						</Col>
					)}
					{section.items.map((item, itemIndex) => {
						let toggledClassName = '';
						if (item === null) return <Col key={`placeholder-${itemIndex}`} xs={2} sm />; // Placeholder for alignment
						if ((item === 'genLotsShiftedInventory' || item === 'comparisonShiftedInventory') && !shiftedInventory) return null;
						if (item === 'genLotsDeliveredInventory' || item === 'comparisonDeliveredInventory') {
							if (shiftedInventory) return null;
							toggledClassName = 'Toggled';
						}
						if ((item === 'genLotsMaxInventory' || item === 'comparisonMaxInventory'
							|| item === 'maxInventory') && !showMaxInventory) return null;

						return (
							<LegendItem
								key={item}
								id={item}
								onToggle={(id, e) => chart && onToggle(chart, {}, id, e)}
								onFocus={(id) => chart && onFocused(chart, id)}
								onBlur={() => chart && chart.revert()}
								additionalClassName={toggledClassName}
								icon={<div className={styles.IconWrapper}>
									{<CustomIcon id={item} />}
								</div>}
								text={item === 'consumptions' && shiftedInventory ? 'Shifted consumption' : getLabel(item, true)}
							/>
						);
					})}
				</Row>
			))}
		</div>
	);
};

const SafetyTimeSwitch = ({ shiftedInventory, setShiftedInventory }) => {
	return (
		<FormControlLabel
			label="Shift by safety time"
			className={styles.SafetyTimeSwitch}
			control={
				<Switch
					checked={shiftedInventory}
					onChange={(e, checked) => setShiftedInventory(checked)}
				/>
			}
		/>
	);
};

const PalletToggle = ({ toggledPallets, setToggledPallets }) => {
	return(
		<ToggleButtonGroup
			value={toggledPallets ? 'pallets' : 'value'}
			className={styles.PalletToggle}
			exclusive
			onChange={() => setToggledPallets(!toggledPallets)}>
			<ToggleButton value="pallets">Pallets</ToggleButton>
			<ToggleButton value="value">Euro</ToggleButton>
		</ToggleButtonGroup>
	)
}

export const ToolBar = ({
													chart, serviceLevel, safetyTime,
													toggledLabels, setToggledLabels,
													expandLegend, setExpandLegend,
													shiftedInventory, setShiftedInventory,
												}) => {
	const serviceLevelText = serviceLevel === false ? '' : `Service level: ${formatShortPercentage(serviceLevel * 100)}%`;
	return (
		<div className={styles.Toolbar}>
			<div className={styles.Information}>{serviceLevelText}</div>
			{safetyTime > 0 && (<SafetyTimeSwitch shiftedInventory={shiftedInventory} setShiftedInventory={setShiftedInventory} />)}
			<div className={styles.Button}>
				<ButtonGroup>
					<ToggleButton title="Show order dates" selected={!!toggledLabels} onChange={() => setToggledLabels(!toggledLabels)}>
						<Label />
					</ToggleButton>
					<Button onClick={() => chart && zoomIn(chart)} title="Zoom In">
						<ZoomIn />
					</Button>
					<Button onClick={() => chart && zoomOut(chart)} title="Zoom Out">
						<ZoomOut />
					</Button>
					<Button onClick={() => setExpandLegend(!expandLegend)} endIcon={expandLegend ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
						Legend
					</Button>
				</ButtonGroup>
			</div>
		</div>
	);
};

export const ToolBarInvViz = ({ chart, toggledPallets, setToggledPallets }) => {
	return (
		<div className={styles.Toolbar} style={{ paddingRight: '50px' }}>
			<PalletToggle toggledPallets={toggledPallets} setToggledPallets={setToggledPallets} />
			<ButtonGroup className={styles.Button}>
				<Button onClick={() => chart && zoomIn(chart)} title="Zoom In">
					<ZoomIn />
				</Button>
				<Button onClick={() => chart && zoomOut(chart)} title="Zoom Out">
					<ZoomOut />
				</Button>
			</ButtonGroup>
		</div>
	)
}
